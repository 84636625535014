module.exports = [{
      plugin: require('../.yarn/__virtual__/@chakra-ui-gatsby-plugin-virtual-19b6f87f52/0/cache/@chakra-ui-gatsby-plugin-npm-3.1.3-9447571880-b1be0bde1b.zip/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-5f22daaf31/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
