exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-lab-tsx": () => import("./../../../src/pages/about/lab.tsx" /* webpackChunkName: "component---src-pages-about-lab-tsx" */),
  "component---src-pages-about-staff-tsx": () => import("./../../../src/pages/about/staff.tsx" /* webpackChunkName: "component---src-pages-about-staff-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-password-tsx": () => import("./../../../src/pages/account/password.tsx" /* webpackChunkName: "component---src-pages-account-password-tsx" */),
  "component---src-pages-account-register-tsx": () => import("./../../../src/pages/account/register.tsx" /* webpackChunkName: "component---src-pages-account-register-tsx" */),
  "component---src-pages-auth-calnet-callback-tsx": () => import("./../../../src/pages/auth/calnet/callback.tsx" /* webpackChunkName: "component---src-pages-auth-calnet-callback-tsx" */),
  "component---src-pages-auth-calnet-index-tsx": () => import("./../../../src/pages/auth/calnet/index.tsx" /* webpackChunkName: "component---src-pages-auth-calnet-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-staff-hours-tsx": () => import("./../../../src/pages/staff-hours.tsx" /* webpackChunkName: "component---src-pages-staff-hours-tsx" */),
  "component---src-pages-stats-tsx": () => import("./../../../src/pages/stats.tsx" /* webpackChunkName: "component---src-pages-stats-tsx" */)
}

